import React from 'react'

function Footer() {
    return (
        <>
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.9970575082616!2d72.86543760105928!3d21.231965275069378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f3ea05be893%3A0xe9974d99bd7c08cc!2sSHREENATHJI%20ICON!5e0!3m2!1sen!2sin!4v1648873761627!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            <div className="copyrights">
                <p>Copyrights © 2017 Scriptoptim Infotech. All rights reserved.</p>
            </div>
        </>
    )
}

export default Footer